import React, { Suspense } from "react";
import { Provider } from "react-redux";
import store from "./store";
import { Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./styles/theme";
import LoadingComponent from "./components/LoadingComponent";
import history from "./services/history";
import { Redirect } from "react-router";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/id";
import "./firebaseInit"
import { onMessageListener } from "./firebaseInit";
import Swal from "sweetalert2";

function App() {
  onMessageListener()
    .then(value => {
      const { title, body } = value.notification;
      const path = window.location.pathname;
      if (path !== '/profile/booking-history/form') {
        Swal.fire({
          imageUrl: '../../assets/images/logos/logo.svg',
          imageWidth: 200,
          imageHeight: 100,
          imageAlt: 'Spine Logo',
          title: title,
          text: body,
          // showCancelButton: true,
          // confirmButtonText: 'Show message',
          // cancelButtonText: `Close`,
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            // history.push({
            //   pathname: `/inbox/detail`,
            //   state: res.data,
            // });
            // history.go(0)
          }
        })
      }
    })
    .catch(err => console.log("error message listener: ", err))
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="id">
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Router history={history}>
            <Suspense fallback={<LoadingComponent />}>
              <Switch>
                <Route
                  path="/login"
                  component={React.lazy(() => import("./pages/UnAuth/Login"))}
                />
                <Route
                  path="/register"
                  component={React.lazy(() =>
                    import("./pages/UnAuth/Register")
                  )}
                />
                <Route
                  exact
                  path="/"
                  component={React.lazy(() => import("./pages/Home"))}
                />
                <Route
                  path="/event"
                  component={React.lazy(() => import("./pages/EventBanner"))}
                />
                <Route
                  exact
                  path="/articles"
                  component={React.lazy(() => import("./pages/Article"))}
                />
                <Route
                  path="/articles/detail"
                  component={React.lazy(() =>
                    import("./pages/Article/Detail")
                  )}
                />
                <Route
                  exact
                  path="/medical-history"
                  component={React.lazy(() =>
                    import("./pages/MedicalHistory")
                  )}
                />
                <Route
                  path="/medical-history/orthotics"
                  component={React.lazy(() =>
                    import("./pages/MedicalHistory/Orthotics")
                  )}
                />
                <Route
                  path="/medical-history/pdf-page"
                  component={React.lazy(() =>
                    import("./pages/MedicalHistory/pdf")
                  )}
                />
                <Route
                  exact
                  path="/inbox"
                  component={React.lazy(() =>
                    import("./pages/Inbox")
                  )}
                />
                <Route
                  path="/inbox/detail"
                  component={React.lazy(() =>
                    import("./pages/Inbox/Detail")
                  )}
                />
                <Route
                  exact
                  path="/profile/booking-history"
                  component={React.lazy(() =>
                    import("./pages/Profile/BookingHistory")
                  )}
                />
                <Route
                  path="/profile/booking-history/form"
                  component={React.lazy(() =>
                    import("./pages/Profile/BookingHistory/Form")
                  )}
                />
                <Route
                  exact
                  path="/profile"
                  component={React.lazy(() => import("./pages/Profile"))}
                />
                <Route
                  path="/profile/change_password"
                  component={React.lazy(() =>
                    import("./pages/Profile/ChangePassword")
                  )}
                />
                <Route
                  path="/profile/article"
                  component={React.lazy(() => import("./pages/Profile/Article"))}
                />
                <Route
                  path="/profile/event"
                  component={React.lazy(() =>
                    import("./pages/Profile/Event")
                  )}
                />
                <Route
                  path="/error"
                  component={React.lazy(() => import("./pages/Error"))}
                />
                <Redirect to="/error" />
              </Switch>
            </Suspense>
          </Router>
        </Provider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
